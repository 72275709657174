a {
    color: #603084;
}

.date-range-input {
    padding: 12px;
    border-radius: 4px;
    border-width: 1px;
    width: 160px;
}

.ReactModal__Overlay{
    z-index: 100000!important;
}
.sigCanvas{
    border: 1px solid black;
    margin: auto;
    border-radius: 12px;
}
.input__datepicker{
    padding: 10px;
}
.quotation_table td{
    border: 1px solid #000;
}
